.App {
  font-size: 0.85rem;

  * {
    box-sizing: border-box;
  }

  h1 {
    margin: 0;
  }

  @media only screen and (max-width: 480px) {
    .hide-for-mobile {
      display: none;
    }
  }
}
