@import "Styles/_colors.scss";

.Home {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 2rem;
    font-weight: 700;
    width: 60%;
    text-align: center;
  }

  &__subtitle {
    font-size: 1.2rem;
    font-weight: 400;
    width: 60%;
    text-align: center;
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &__item {
      padding: 1rem;
      width: 20rem;
      height: 20rem;
      border-radius: 5px;
      margin: 0 0.5rem;
      background: rgba(255, 255, 255, 0.985);
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        text-align: center;
      }

      @media only screen and (max-width: 480px) {
        margin: 0.5rem;
      }

      &__title {
        font-size: 1.2rem;
        font-weight: 700;
        text-align: center;
      }

      &__list {
        .list__li {
          margin: 0.25rem 0;
          display: flex;
          justify-content: space-between;
        }
      }

      &__submit {
        display: flex;
        justify-content: center;

        .Button {
          width: 95%;
        }
      }
    }
  }

  .Staking {
    &__amount {
      font-size: 1.75rem;
      font-weight: 700;
      text-align: center;
      word-break: break-word;
    }

    &__amount-title {
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }
  }

  .Stake {
    margin-top: 1.5rem;

    &__available {
      text-align: center;
    }

    &__input {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;

      > span {
        width: 15rem;

        > input {
          width: 15rem;
        }
      }
    }
  }

  .Unclaimed {
    &__amount {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }

    &__label {
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }

    &__user-total {
      margin-top: 1rem;
      font-size: 0.8rem;
      color: $secondary-theme;
      text-align: center;
    }
  }

  @media only screen and (max-width: 480px) {
    &__items {
      flex-direction: column;
    }
  }
}
