@import "Styles/_colors.scss";

.Header {
  padding: 0.25rem 10vw;
  background: $complementary-theme;
  color: #fff;

  &__info {
    display: flex;
    justify-content: space-between;

    &__logo {
      svg {
        height: 3rem;
        width: auto;
      }
    }

    &__right-menu {
      display: flex;
      align-items: center;

      > span {
        margin: 0 0.5rem;
      }

      &__address {
        padding: 0.25rem 0.5rem;
        background: $secondary-theme;
        border-radius: 4px;
        width: 10rem;
        text-align: center;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
      }

      &__utrade {
        text-decoration: none;
        background: transparentize($color: $primary-theme, $amount: 0.1);
        color: #000;
        padding: 0.25rem 0.5rem;
        border-radius: 4px;
        transition: background 0.3s;

        &:hover {
          background: transparentize($color: $primary-theme, $amount: 0.3);
        }
      }
    }
  }

  &__children {
    > div {
      padding: 4rem 0;
    }
  }

  @media only screen and (max-width: 480px) {
    &__info {
      flex-direction: column;

      > span {
        display: flex;
        justify-content: center;
        flex-direction: column;
      }

      &__right-menu {
        margin-top: 1rem;

        > span {
          margin: 0.3rem 0;
        }
      }
    }

    &__children {
      > div {
        padding: 1rem 0;
      }
    }
  }
}
